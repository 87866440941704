import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/testimonial.jpg';

const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">

          <div className="testi">
            <div className="quotation-mark">“</div>“This test opened my eyes and prevented me from drifting along with the wrong ChMS just because of switching costs. Plus, I never knew that our team members also felt so strongly about it. This is the alignment we needed and it helped us take the action we wanted.”<div className="quotation-mark bottom"><div className="quotation-block">“</div></div>
          </div>

          <div className="avatar">
            <img src={testimonialImage} alt="pastor" />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Executive Pastor, New Life Church</span>
          </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;
